

export const Logo = () => {


  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 67.52 67.61"
    >
      <circle cx="33.77"
cy="33.86"
r="33.75"
fill={"#583f99"} />
      <path
        d="M272.73,395.33"
        transform="translate(-272.71 -362.11)"
        fill={"#583f99"}
      />
      <path
        d="M279,395.33"
        transform="translate(-272.71 -362.11)"
        fill={"#583f99"}
      />
      <path
        d="M307.73,362.22s1,8-1,11-3,4-7,1-8,0-8,4a5.75,5.75,0,0,0,5.34,5.89c2,0,4.16-3.33,6.66-2.89,2.28.11,3.84,1.83,3.84,7.83s-.84,6.78-.84,6.78a89.3,89.3,0,0,0-9-.61c-4,0-6,3-6,4-.25,2.67,2.73,3.11,2.73,6.11,0,2.63-1.28,4.34-2.73,4.89s-5,1-7-1-1.5-4.05-.55-5.89,3-2.4,1.78-5.28-5.23-2.83-7.23-2.83-6,.11-6,.11"
        transform="translate(-272.71 -362.11)"
        fill={"#583f99"}
      />
      <path
        d="M303.83,380.35c-1.72-.28-3.19.77-4.49,1.7a5.21,5.21,0,0,1-2.27,1.18,4.89,4.89,0,0,1-4.46-5,4.4,4.4,0,0,1,2.27-4,4,4,0,0,1,4.33.68c2.13,1.6,3.51,2.16,4.77,1.94,1.44-.24,2.35-1.46,3.48-3.15,2.12-3.18,1.25-10.75,1.14-11.6l-1.73.22c.26,2.13.63,8.17-.86,10.41-1,1.5-1.64,2.28-2.32,2.4s-1.8-.39-3.43-1.62a5.75,5.75,0,0,0-6.16-.84,6.15,6.15,0,0,0-3.24,5.54,6.58,6.58,0,0,0,6.21,6.76,6.17,6.17,0,0,0,3.29-1.51c1.08-.77,2.2-1.56,3.22-1.39h.11c.9.05,3,.15,3,7a22.76,22.76,0,0,1-.49,5.84c-1.48-.16-5.26-.54-8.47-.54-4.53,0-6.87,3.41-6.87,4.79a4.49,4.49,0,0,0,1.47,3.58,3.74,3.74,0,0,1,1.25,2.61c0,2.4-1.17,3.69-2.16,4.07-1.19.46-4.37.9-6.07-.8-1.36-1.36-1.47-2.77-.39-4.87a6.72,6.72,0,0,1,.89-1.21,4,4,0,0,0,.92-4.8c-1.4-3.38-5.76-3.37-7.86-3.37h-.18c-2,0-6,.11-6,.11l.05,1.75s4-.11,6-.11h.18c1.84,0,5.29,0,6.24,2.29.63,1.53.18,2.05-.63,3a8.15,8.15,0,0,0-1.12,1.55c-.9,1.76-1.81,4.38.72,6.91a6.69,6.69,0,0,0,4.79,1.73,9.24,9.24,0,0,0,3.14-.53c1.57-.61,3.26-2.42,3.28-5.71a5.38,5.38,0,0,0-1.68-3.76,2.85,2.85,0,0,1-1-2.35c0-.46,1.53-3.12,5.12-3.12a88.4,88.4,0,0,1,8.9.6l.4.05.3-.28c.72-.67,1.11-3.3,1.11-7.42C308.44,385.43,308,380.59,303.83,380.35Z"
        transform="translate(-272.71 -362.11)"
        fill={"#fff"}
      />
      <path
        d="M305.9,429.05s-.5-7.83.5-9.83,2.33-5,7.33-1c4.34,3.72,7-2,7-2a6.13,6.13,0,0,0-1.16-6.83c-4.72-3.28-6.61,1.56-9.28,1.78-2.06.16-6.56,1.05-3.81-15.2,0,0,2.14,1.53,11.42.75,4-.33,5-4.72,2.83-6.5-3.55-2.89-2.1-8.41,2.89-8.61,4.11-.17,6.34,1.44,6.45,4.39.15,4-3.57,4.22-3,7.17.72,3.77,5.83,3.83,5.83,3.83l6.83-.17"
        transform="translate(-272.71 -362.11)"
        fill={"#583f99"}
      />
      <path
        d="M305,429.11c0-.83-.49-8.13.59-10.28l.07-.14c.6-1.2,1.42-2.85,3.17-3.24,1.49-.32,3.26.36,5.42,2.09a3.28,3.28,0,0,0,2.8,1c1.74-.37,2.85-2.68,2.86-2.7a5.25,5.25,0,0,0-1-5.81c-2.55-1.72-4-.68-5.59.52a6.18,6.18,0,0,1-3,1.48h-.18a4.19,4.19,0,0,1-3.79-1.12c-1.92-2.07-2.16-6.87-.77-15.11l.24-1.37,1.13.81h0s2.25,1.31,10.84.59a3.28,3.28,0,0,0,3-2.25,2.47,2.47,0,0,0-.67-2.7,5.84,5.84,0,0,1-2-6.37,5.72,5.72,0,0,1,5.42-3.79c2.57-.1,4.52.44,5.8,1.61a5,5,0,0,1,1.55,3.62,6,6,0,0,1-2,4.7c-.82.91-1.21,1.39-1,2.33.58,3,4.94,3.12,5,3.12l6.8-.16.05,1.75-6.84.16c-.27,0-5.87-.11-6.71-4.54-.35-1.85.61-2.9,1.46-3.84a4.34,4.34,0,0,0,1.52-3.46,3.29,3.29,0,0,0-1-2.39c-.91-.84-2.47-1.24-4.54-1.16a4,4,0,0,0-3.83,2.6,4.1,4.1,0,0,0,1.46,4.46,4.24,4.24,0,0,1,1.24,4.57,5,5,0,0,1-4.56,3.48c-6.15.52-9.3,0-10.8-.4-1.32,8.45-.47,11.5.51,12.56a2.51,2.51,0,0,0,2.34.56l.2,0a4.83,4.83,0,0,0,2.12-1.14c1.73-1.27,4.08-3,7.73-.48l.17.15c3.35,4,1.35,7.67,1.26,7.83s-1.45,3.05-4.05,3.61a4.88,4.88,0,0,1-4.29-1.38c-1.65-1.31-3-1.91-3.92-1.72s-1.5,1.33-2,2.31l-.07.14c-.65,1.31-.59,6.45-.41,9.39Z"
        transform="translate(-272.71 -362.11)"
        fill={"#fff"}
      />
    </svg>
  );
};
