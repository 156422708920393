export const useMockedUser = () => {

  var item_value = JSON.parse(sessionStorage.getItem("userDetails"));
  var user_Perms = JSON.parse(sessionStorage.getItem("userPermission"));

  var retData = {
    id: item_value.userId,
    avatar: '/assets/avatars/avatar-anika-visser.png',
    name: item_value.userFirstName,
    email: item_value.userEmail,
    firstName: item_value.userFirstName,
    middleName: item_value.userMiddleName,
    lastName: item_value.userLastName,
    permissions:user_Perms?.capability,
    isSuper:user_Perms?.isSuper
  }

  return retData
};
