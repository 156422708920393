import React, { createContext, useContext, useState } from "react";

const ProfilePictureContext = createContext();

export const ProfilePictureProvider = ({children}) => {
    const [profilePictureUploaded, setProfilePictureUploaded] = useState(false);

    const setStateOfProfilePicture = (bool) => {
        setProfilePictureUploaded(bool);
    };

    return(
        <ProfilePictureContext.Provider
        value={{setStateOfProfilePicture, profilePictureUploaded}}>
            {children}
        </ProfilePictureContext.Provider>
    );
};

export const useProfilePicture = () => {
    return useContext(ProfilePictureContext);
};