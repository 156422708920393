import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { AuthGuard } from "src/guards/auth-guard";
import { Layout as DashboardLayout } from "src/layouts/dashboard";
import { ProfilePictureProvider } from "src/contexts/profile-upload";
// import { element } from "prop-types";
const IndexPage = lazy(() => import("src/pages/dashboard/index"));
// Organizations
const OrganizationsListPage = lazy(() => import("src/pages/organization"));
const OrganizationsDetailPage = lazy(() =>
  import("src/pages/organization/detail")
);
const OrganizationsEditPage = lazy(() => import("src/pages/organization/edit"));
const OrganizationsAddPage = lazy(() => import("src/pages/organization/add")); // Invoice
const InvoiceListPage = lazy(() => import("src/pages/dashboard/invoices/list"));
const InvoiceDetailPage = lazy(() =>
  import("src/pages/dashboard/invoices/detail")
);

//Email templates
const EmailPreviewPage = lazy(() =>
  import("src/pages/organization/emailTemplates/emailPreview")
);
const EmailTemplateEditPage = lazy(() =>
  import("src/pages/organization/emailTemplates/emailTemplateEdit")
);

//Survey email template
const SurveyEmailTemplateList = lazy(() =>
  import("src/pages/organization/emailTemplates/surveyEmailTemplateList")
);
const SurveyEmailPreviewPage = lazy(() =>
  import("src/pages/organization/emailTemplates/surveyEmailPreview")
);
const SurveyEmailTemplateEditPage = lazy(() =>
  import("src/pages/organization/emailTemplates/surveyEmailTemplateEdit")
);

//CustomerUsers
const CustomerUsersAddPage = lazy(() =>
  import("src/pages/organization/customerUsers/customerUsersAdd")
);
const CustomerUsersDetailsPage = lazy(() =>
  import("src/pages/organization/customerUsers/customerUserDetails")
);
const CustomerUserEditPage = lazy(() =>
  import("src/pages/organization/customerUsers/customerUserEdit")
);

//Individual customers

const IndividualCustomerListPage = lazy(() => import("src/pages/individualCustomer/index"));

const IndividualCustomerAddPage = lazy(() => import("src/pages/individualCustomer/add"));

const IndividualCustomerDetailPage = lazy(() => import("src/pages/individualCustomer/detail"));

const IndividualCustomerEditPage = lazy(() => import("src/pages/individualCustomer/edit"));

const ProgrammeParticipantListPage = lazy(() =>
  import("src/pages/programmeParticipant/index")
);
const ProgrammeParticipantAddPage = lazy(() =>
  import("src/pages/programmeParticipant/add")
);
const ProgrammeParticipantDetailPage = lazy(() =>
  import("src/pages/programmeParticipant/detail")
);
const ProgrammeParticipantEditPage = lazy(() =>
  import("src/pages/programmeParticipant/edit")
);

//Coaches corner
const CoachesCornerListPage = lazy(() =>
  import("src/pages/coachesCorner/index")
);
const CoachesCornerAddPage = lazy(() => import("src/pages/coachesCorner/add"));
const CoachesCornerDetailsPage = lazy(() =>
  import("src/pages/coachesCorner/detail")
);
const CoachesCornerEditPage = lazy(() =>
  import("src/pages/coachesCorner/edit")
);

// Users
const UserListPage = lazy(() => import("src/pages/users/index"));
const UserDetailPage = lazy(() => import("src/pages/users/detail"));
const UserEditPage = lazy(() => import("src/pages/users/edit"));
const UserAddPage = lazy(() => import("src/pages/users/add"));
const UserReportPage = lazy(() =>
  import("src/pages/users/users-report/report")
);
const UserInactivePage = lazy(() =>
  import("src/pages/users/users-report/inactive")
);
const UserInactiveDetailsPage = lazy(() =>
  import("src/pages/users/users-report/details")
);
const EmailLog = lazy(() => import("src/pages/users/users-report/emailIndex"));

// Role Management
const RoleListPage = lazy(() => import("src/pages/roleManagement/roleList"));
const RoleAddPage = lazy(() => import("src/pages/roleManagement/add"));
const RoleDetailPage = lazy(() => import("src/pages/roleManagement/details"));
const RoleEditPage = lazy(() => import("src/pages/roleManagement/edit"));

//Survey Management
const Surveys = lazy(() => import("src/pages/surveys/index"));
const QuestionDetails = lazy(() => import("src/pages/surveys/details"));
//class and syndicate

//class and syndicate
const Classes = lazy(() => import("src/pages/class"));
const ClassAddPage = lazy(() => import("src/pages/class/add"));
const ProgrammeDetailPage = lazy(() => import("src/pages/class/details"));
const ProgrammeEditPage = lazy(() => import("src/pages/class/edit"));
const SyndicateDetailsPage = lazy(() =>
  import("src/pages/class/syndicate/syndicate-management")
);

// Profile page

const ProfilePage = lazy(() => import("src/pages/profile/profile"));

// Survey Template

const SurveyTemplate = lazy(() =>
  import("src/pages/surveytemplates/surveytemplate")
);
//team
const TeamDetails = lazy(() =>
  import("src/pages/organization/DivisionTeam/team-full-details")
);

const LeadSettings = lazy(() => import("src/pages/leadSettings/leadSettings"));
const ISLAluminiPage = lazy(() =>
  import("src/pages/islAlumini/islAluminiPage")
);
const LeadSettingsView = lazy(() =>
  import("src/pages/leadSettings/overview/ViewPage")
);

const SurveyQuestionsDetails = lazy(() =>
  import("src/sections/dashboard/customer/surveyQuestions/details/SurveyQuestionsDetails")
);

export const dashboardRoutes = [
  {
    path: "",
    element: (
      <AuthGuard>
        <ProfilePictureProvider>
          <DashboardLayout>
            <Suspense>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </ProfilePictureProvider>
      </AuthGuard>
    ),
    children: [
      {
        path: "dashboard",
        index: true,
        element: <IndexPage />,
      },
      {
        path: "organizations",
        children: [
          {
            index: true,
            element: <OrganizationsListPage />,
          },
          {
            path: ":customerId",
            element: <OrganizationsDetailPage />,
          },
          {
            path: ":customerId/edit",
            element: <OrganizationsEditPage />,
          },
          {
            path: "add",
            element: <OrganizationsAddPage />,
          },
          {
            path: ":customerId/customerUsers",
            children: [
              {
                path: "add",
                element: <CustomerUsersAddPage />,
              },
              {
                path: ":cpTenantId/details/:userId",
                element: <CustomerUsersDetailsPage />,
              },
              {
                path: "edit/:cpTenantId/:userId",
                element: <CustomerUserEditPage />,
              },
            ],
          },
          {
            path: ":emailId/emailTemplate",
            children: [
              {
                path: ":cpTenantId/:customerId",
                element: <EmailPreviewPage />,
              },
              {
                path: "edit/:cpTenantId/:customerId",
                element: <EmailTemplateEditPage />,
              },
            ],
          },
          {
            path: "surveyEmailTemplate",
            children: [
              {
                path: ":surveyId/:cpTenantId/:customerId/:surveyName",
                element: <SurveyEmailTemplateList />,
              },
              {
                path: "preview/:surveyId/:emailId/:cpTenantId/:customerId/:surveyName",
                element: <SurveyEmailPreviewPage />,
              },
              {
                path: "edit/:surveyId/:emailId/:cpTenantId/:customerId/:surveyName",
                element: <SurveyEmailTemplateEditPage />,
              },
            ],
          },
          {
            path: ":customerId/team/:teamId",
            element: <TeamDetails />,
          },
        ],
      },
      {
        path: "programmeParticipant",
        children: [
          {
            index: true,
            element: <ProgrammeParticipantListPage />,
          },
          {
            path: "add",
            element: <ProgrammeParticipantAddPage />,
          },
          {
            path: ":programmeId/:individualCustomerId",
            element: <ProgrammeParticipantDetailPage />,
          },
          {
            path: ":programmeId/:individualCustomerId/edit",
            element: <ProgrammeParticipantEditPage />,
          },
        ],
      },
      {
        path: "individuals",
        children: [
          {
            index: true,
            element: <IndividualCustomerListPage />,
          },
          {
            path: "add",
            element: <IndividualCustomerAddPage />,
          },
          {
            path: ":individualCustomerId",
            element: <IndividualCustomerDetailPage />,
          },
          {
            path: ":individualCustomerId/edit",
            element: <IndividualCustomerEditPage />,
          },
        ],
      },
      {
        path: "users",
        children: [
          {
            index: true,
            element: <UserListPage />,
          },
          {
            path: "add",
            element: <UserAddPage />,
          },
          {
            path: ":userId",
            element: <UserDetailPage />,
          },
          {
            path: ":userId/edit",
            element: <UserEditPage />,
          },
          {
            path: "report",
            element: <UserReportPage />,
          },
          {
            path: "inactive",
            element: <UserInactivePage />,
          },
          {
            path: "inactive/:userId",
            element: <UserInactiveDetailsPage />,
          },
          {
            path: "email",
            element: <EmailLog />,
          },
        ],
      },
      {
        path: "roles",
        children: [
          {
            index: true,
            element: <RoleListPage />,
          },
          {
            path: "add",
            element: <RoleAddPage />,
          },
          {
            path: ":roleId",
            element: <RoleDetailPage />,
          },
          {
            path: ":roleId/edit",
            element: <RoleEditPage />,
          },
        ],
      },
      {
        path: "classes",
        children: [
          {
            index: true,
            element: <Classes />,
          },
          {
            path: "add",
            element: <ClassAddPage />,
          },
          {
            path: ":programmeId",
            element: <ProgrammeDetailPage />,
          },
          {
            path: ":programmeId/edit",
            element: <ProgrammeEditPage />,
          },
          {
            path: ":programmeId/syndicate/:syndicateId",
            element: <SyndicateDetailsPage />,
          },
        ],
      },
      {
        path: "surveyTemplates",
        element: <SurveyTemplate />,
      },
      {
        path: "surveys",
        children: [
          {
            index: true,
            path: ":surveyId",
            element: <Surveys />,
          },
          {
            index: true,
            path: ":surveyId/:questionId/details",
            element: <QuestionDetails />,
          },
        ],
      },
      {
        path: "invoices",
        children: [
          {
            index: true,
            element: <InvoiceListPage />,
          },
          {
            path: ":invoiceId",
            element: <InvoiceDetailPage />,
          },
        ],
      },
      {
        path: "profile",
        element: <ProfilePage />,
      },
      {
        path: "coachesCorner",
        children: [
          {
            index: true,
            element: <CoachesCornerListPage />,
          },
          {
            path: "add",
            element: <CoachesCornerAddPage />,
          },
          {
            path: ":coachId",
            element: <CoachesCornerDetailsPage />,
          },
          {
            path: ":coachId/edit",
            element: <CoachesCornerEditPage />,
          },
        ],
      },
      {
        path: "leadSettings/",
        element: <LeadSettings />,
      },
      {
        path: "islAlumini",
        element: <ISLAluminiPage />,
      },
      {
        path: "leadSettingsView/:data_type/:id/:tenant_id",
        element: <LeadSettingsView />,
      },
      {
        path: "surveyquestionsdetails/:customerid/:tenantId/:questionId",
        element: <SurveyQuestionsDetails />,
      }
    ],
  },
];
