import User01Icon from '@untitled-ui/icons-react/build/esm/User01';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import SvgIcon from '@mui/material/SvgIcon';
// import { useMockedUser } from 'src/hooks/use-mocked-user';
import { usePopover } from 'src/hooks/use-popover';
import { AccountPopover } from './account-popover';
import UserapiService from 'src/services/userApi';
import { useEffect, useState } from 'react';
import { useProfilePicture } from 'src/contexts/profile-upload';

export const AccountButton = () => {
  // const user = useMockedUser();
  const popover = usePopover();
  const [profilepicData,setProfilePicData] = useState('')
  const profileData = sessionStorage.getItem('userDetails')
  const profileDataParsed = JSON.parse(profileData)
  const {profilePictureUploaded} = useProfilePicture();

  const getProfilePicture = async()=>{
    try {
      const response = await UserapiService.getProfilePicture(profileDataParsed.userId)
        console.log("hhggf",response.mediaData[0].data);
        setProfilePicData(response.mediaData[0].data);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(()=>{
    getProfilePicture()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    getProfilePicture()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[profilePictureUploaded]);
  
  return (
    <>
      <Box
        component={ButtonBase}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: 'divider',
          height: 40,
          width: 40,
          borderRadius: '50%'
        }}
      >
        <Avatar
          sx={{
            height: 32,
            width: 32
          }}
          alt="User Avatar"
          src={`data:image/png;base64,${profilepicData}`}
        >
          <SvgIcon>
            <User01Icon />
          </SvgIcon>
        </Avatar>
      </Box>
      <AccountPopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  );
};
