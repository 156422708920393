/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:29:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-05-15 12:15:40
 */
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { RouterLink } from "src/components/router-link";
import { paths } from "src/paths";
import { Logo } from "src/components/logo";
export const Layout = (props) => {
  const { children } = props;

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        display: "flex",
        flex: "1 1 auto",
        flexDirection: {
          xs: "column-reverse",
          md: "row",
        },
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          height: "100vh",
          width: "100vw",
          overflow: "hidden",
          backgroundColor: "neutral.800",
          backgroundImage: 'url("/assets/gradient-bg.svg")',
          backgroundPosition: "top center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          color: "common.white",
          display: "flex",
          flex: {
            xs: "0 0 auto",
            md: "1 1 auto",
          },
          justifyContent: "center",
          p: {
            xs: 4,
            md: 8,
          },
        }}
      >
        <Box maxWidth="md">
          <Typography sx={{ mb: 1 }}
          variant="h4">
            Welcome to <br/>SmartLeader Admin Portal
          </Typography>
          <Stack
            alignItems="center"
            direction="row"
            flexWrap="wrap"
            gap={4}
            sx={{
              color: "text.primary",
              "& > *": {
                color: "neutral.400",
                flex: "0 0 auto",
              },
            }}
          ></Stack>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "background.paper",
          display: "flex",
          flex: {
            xs: "1 1 auto",
            md: "0 0 auto",
          },
          flexDirection: "column",
          justifyContent: {
            md: "center",
          },
          maxWidth: "100%",
          py: {
            xs: 4,
            md: 4,
          },
          px: {
            xs: 4,
            md: 8,
          },
          width: {
            md: 600,
          },
        }}
      >
        <div>
          <Box
            sx={{
              mb: 4,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: 0,
            }}
          >
            <Stack
              alignItems="center"
              component={RouterLink}
              direction="row"
              display="inline-flex"
              href={paths.index}
              spacing={1}
              sx={{ textDecoration: "none" }}
            >
              <Box
                sx={{
                  display: "inline-flex",
                  width: 80,
                  justifyContent: "center",
                  alignItems:'center'
                }}
              >
                <Logo />
              </Box>
            </Stack>
          </Box>
          <Box 
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "text.primary",
              fontFamily: "'Plus Jakarta Sans', sans-serif",
              fontSize: 18,
              fontWeight: 800,
              letterSpacing: "0.3px",
              lineHeight: 2.5,
              "& span": {
                color: "primary.main",
              },
            }}
          >
           
          </Box>
          {children}
        </div>
      </Box>
    </Box>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};
